import styled from "styled-components";
import { Text } from "../Text";
import Image from "next/image";
import dayjs from "dayjs";
import { useRouter } from "next/router";

const StyledCard = styled("div")`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;

const StyledText = styled(Text)`
  font-family: "Noto Sans Thai", sans-serif;
`;

const StyledImageContainer = styled("div")`
  width: 100%;
  img {
    object-fit: cover !important;
    width: 100% !important;
    position: relative !important;
    height: 200px !important;
  }
`;

const TournamentInfo: React.FC<{ item: TournamentData }> = ({ item }) => {
  const router = useRouter();
  return (
    <StyledCard
      key={item.id}
      onClick={() => router.push(`/tournament/${item.id}`)}
    >
      <StyledImageContainer>
        <Image src={item.banner} alt="sdsf" fill />
      </StyledImageContainer>

      <StyledText mt="16px" fontWeight="700" fontSize="20px" lineHeight="27px">
        {item.name}
      </StyledText>
      <StyledText mt="7px">
        {dayjs(item.start_date).format("DD.MM.YYYY")}
      </StyledText>
    </StyledCard>
  );
};

export default TournamentInfo;
