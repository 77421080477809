import React, { useState, useRef, useEffect, ReactNode } from "react";
import styled from "styled-components";

interface PopoverProps {
  content: ReactNode;
  trigger: "click" | "hover";
  children: React.ReactNode;
}

const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const PopoverContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
`;

const Popover: React.FC<PopoverProps> = ({ content, trigger, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleTriggerClick = () => {
    setIsOpen(!isOpen);
  };

  const handleTriggerHover = () => {
    setIsOpen(true);
  };

  return (
    <PopoverContainer ref={popoverRef}>
      {trigger === "click" ? (
        <div onClick={handleTriggerClick}>{children}</div>
      ) : (
        <div
          onMouseEnter={handleTriggerHover}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children}
        </div>
      )}

      {isOpen && <PopoverContent>{content}</PopoverContent>}
    </PopoverContainer>
  );
};

export default Popover;
