import { useRouter } from "next/router";
import { FC } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "../";
import dayjs from "dayjs";
import useBreakpoint from "../../hooks/useBreakpoint";
import Image from "next/image";

const StyledCard = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;

const StyledText = styled(Text)`
  font-family: "Noto Sans Thai", sans-serif;
`;

const StyledImageContainer = styled("div")`
  width: 100%;
  box-shadow: 0px 0px 74px rgba(67, 166, 255, 0.59);

  img {
    object-fit: cover !important;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`;

const NewsInfo: FC<{ item: News; index: number }> = ({ item, index }) => {
  const router = useRouter();
  const screens = useBreakpoint();
  const handleClick = () => {
    router.push(`/news/${item.id}`);
  };

  return (
    <StyledCard
      onClick={handleClick}
      flexDirection={
        !screens.xs ? (index % 2 ? "column-reverse" : "column") : "column"
      }
      alignItems="stretch"
    >
      <Box>
        <StyledImageContainer>
          <Image src={item.banner} alt={item.name} fill />
        </StyledImageContainer>
      </Box>
      <Box>
        <StyledText mt="16px">
          {dayjs(item.start_date).format("DD.MM.YYYY")}
        </StyledText>
        <StyledText mt="3px" fontWeight="700" fontSize="20px" lineHeight="27px">
          {item.name}
        </StyledText>
      </Box>
    </StyledCard>
  );
};

export default NewsInfo;
