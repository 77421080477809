import ReactPaginate from "react-paginate";
import styled from "styled-components";

const Pagination = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
  list-style: none;
  gap: 20px;

  li {
    a {
      padding: 12px 20px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #ccc;
        color: white;
        a {
          color: black !important;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }

  .selected a {
    background-color: #ccc;
  }
`;

export default Pagination;
