import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'
import { TextProps } from './types'

const getFontSize = ({ fontSize, small }: TextProps) =>
  small ? '14px' : fontSize || '16px'

const Text = styled.span<TextProps>`
  font-size: ${getFontSize};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
  ${space}
  ${typography}
  ${layout}
`

Text.defaultProps = {
  small: false,
  ellipsis: false,
}

export default Text
