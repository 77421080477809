import styled from "styled-components";

const RedButton = styled.button`
  z-index: 2;
  background-image: url(/images/red-button.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 101px;
  width: 240px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #ffffff;
  text-shadow: 0px 0px 4px #9acaf4;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;

export default RedButton;
