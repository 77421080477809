import React, { useState } from "react";
import styled from "styled-components";

interface Option {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  options: Option[];
  defaultValue?: string[];
  onChange?: (values: string[]) => void;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  defaultValue,
  onChange,
}) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(
    defaultValue || []
  );

  const handleChange = (value: string, isChecked: boolean) => {
    const newCheckedValues = isChecked
      ? [...checkedValues, value]
      : checkedValues.filter((checkedValue) => checkedValue !== value);
    setCheckedValues(newCheckedValues);

    if (onChange) {
      onChange(newCheckedValues);
    }
  };

  return (
    <Container>
      {options.map((option) => (
        <Checkbox
          key={option.value}
          label={option.label}
          value={option.value}
          checked={checkedValues.includes(option.value)}
          onChange={handleChange}
        />
      ))}
    </Container>
  );
};

interface CheckboxProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  value,
  checked,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value, event.target.checked);
  };

  return (
    <Label>
      <StyledCheckbox
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      {label}
    </Label>
  );
};

const Label = styled.label`
  display: inline-flex;
  align-items: start;
  cursor: pointer;
  margin-right: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  font-family: "Noto Sans Thai", sans-serif;
`;

const StyledCheckbox = styled.input`
  min-width: 20px;
  min-height: 20px;
  background-color: transparent;
  margin-right: 15px;
  border: 1px solid #ffffff;
  appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: #ffffff;
  }
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default CheckboxGroup;
