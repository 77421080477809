import React from "react";
import styled from "styled-components";
import Link from "next/link";

type BreadcrumbItem = {
  title: string;
  link?: string;
};

type BreadcrumbProps = {
  items: BreadcrumbItem[];
  current: string;
};
const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  font-family: "Noto Sans Thai", sans-serif;
`;

const BreadcrumbItemLink = styled.div<{ isCurrent: boolean }>`
  color: white;
  font-weight: ${(props) => (props.isCurrent ? "bold" : "normal")};
  text-decoration: none;
  &:hover {
    text-decoration: ${(props) => (props.isCurrent ? "none" : "underline")};
  }
`;

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, current }) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        const isCurrent = item.title === current;

        return (
          <React.Fragment key={item.title}>
            {item.link ? (
              <Link href={item.link}>
                <BreadcrumbItemLink isCurrent={isCurrent}>
                  {item.title}
                </BreadcrumbItemLink>
              </Link>
            ) : (
              <BreadcrumbItemLink isCurrent={isCurrent}>
                {item.title}
              </BreadcrumbItemLink>
            )}
            {!isLast && <span>&gt;</span>}
          </React.Fragment>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
