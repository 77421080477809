import styled from "styled-components";
import { Flex, Text, Box } from "../../components";
import Image from "next/image";
import LogoImage from "public/images/logo.png";
import FacebookIcon from "public/images/facebook-icon.svg";
import TwitterIcon from "public/images/twitter-icon.svg";
import TelegramIcon from "public/images/telegram-icon.svg";
import DiscordIcon from "public/images/discord-icon.svg";
import ArtIcon from "public/images/art-icon.svg";
import Link from "next/link";
import useBreakpoint from "../../hooks/useBreakpoint";

const Wrapper = styled("footer")`
  margin-top: 90px;
`;

const Container = styled("div")`
  position: realtive;
  margin: 0 auto;
  padding: 32px 0;
  max-width: 1440px;
  width: 90%;
`;

const StyledSocial = styled("div")`
  transition: all 0.05s linear;
  :hover {
    transform: scale(1.1);
  }
`;

const SocialLogo = styled(Box)`
  img:hover {
    filter: invert(19%) sepia(86%) saturate(7470%) hue-rotate(329deg)
      brightness(105%) contrast(116%);
  }
`;

const Copyright = () => (
  <Flex alignItems="center" style={{ gap: "32px" }} flexWrap="wrap">
    <Text
      fontSize="12px"
      lineHeight="14px"
      color="white"
      letterSpacing="0.1em"
      style={{ textTransform: "uppercase" }}
    >
      ©2023 PRO HUB THAILAND. All rights reserved.
    </Text>
    <Flex alignItems="center" style={{ gap: "12px" }}>
      <Link href="/privacy" target="_self">
        <Text
          fontSize="12px"
          lineHeight="14px"
          color="white"
          letterSpacing="0.1em"
          style={{ textTransform: "uppercase" }}
        >
          Privacy Policy
        </Text>
      </Link>
      <Link href="/terms" target="_self">
        {" "}
        <Text
          fontSize="12px"
          lineHeight="14px"
          color="white"
          letterSpacing="0.1em"
          style={{ textTransform: "uppercase" }}
        >
          Term & Conditions
        </Text>
      </Link>
    </Flex>
  </Flex>
);
const Footer = () => {
  const socials = [
    {
      src: TwitterIcon,
      alt: "twitter-icon",
      href: "https://twitter.com/prohubthailand",
      target: "_blank",
      tooltipText: "Twitter",
    },
    {
      src: FacebookIcon,
      alt: "facebook-icon",
      href: "https://www.facebook.com/PROHUBTHAILAND",
      target: "_blank",
      tooltipText: "Facebook",
    },
    {
      src: DiscordIcon,
      alt: "discord-icon",
      href: "https://discord.gg/EaEMkVjft3",
      target: "_blank",
      tooltipText: "Discord",
    },
    // {
    //   src: ArtIcon,
    //   alt: "art-icon",
    //   href: "https://www.instagram.com/prohubthailand/",
    //   target: "_blank",
    //   tooltipText: "Art",
    // },
    // {
    //   src: TelegramIcon,
    //   alt: "telegram-icon",
    //   href: "https://www.facebook.com/",
    //   target: "_blank",
    //   tooltipText: "Telegram",
    // },
  ];
  const screens = useBreakpoint();
  const handleError = () => {
    throw new Error("hello");
  };
  return (
    <Wrapper>
      <Container>
        <Text
          style={{ cursor: "pointer", width: "25px", height: "25px" }}
          onClick={() => handleError()}
        ></Text>
        <Flex
          alignItems="flex-end"
          justifyContent={screens.xs ? "flex-start" : "space-between"}
          flexWrap="wrap"
          style={!screens.xs ? { gap: "50px" } : { gap: "30px" }}
        >
          <Flex
            flexDirection="column"
            justifyContent={screens.xs ? "center" : ""}
            flexWrap="wrap"
            alignItems="flex-start"
            style={
              screens.xs
                ? { textAlign: "center", gap: "20px" }
                : { gap: "20px" }
            }
          >
            <Image src={LogoImage} alt="prohub-logo" width={236} />
            {!screens.xs && <Copyright />}
          </Flex>
          <Flex alignItems="center" style={{ gap: "8px" }}>
            <Text color="white" fontSize="12px">
              FOLLOW US ON :{" "}
            </Text>
            {socials.map((item, index) => (
              <StyledSocial key={index}>
                <Link href={item.href} target={item.target}>
                  <SocialLogo>
                    <Image src={item.src} alt={item.alt} />
                  </SocialLogo>
                </Link>
              </StyledSocial>
            ))}
          </Flex>
          {screens.xs && <Copyright />}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default Footer;
