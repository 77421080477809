import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'
import { TextProps } from './types'

const getFontSize = ({ fontSize, small }: TextProps) =>
  small ? '14px' : fontSize || '14px'

const Text = styled.div<TextProps>`
  font-size: ${getFontSize};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ heading }) =>
    heading &&
    `
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFCC00;
      text-shadow: 0px 4px 0px #000000;
      font-weight: 800;
  `}
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
    ${(props) =>
    props.gradient &&
    `
    background: linear-gradient(90deg, #00D2FF 14.64%, #02D767 85.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  `}
  ${space}
  ${typography}
  ${layout}
`;

Text.defaultProps = {
  small: false,
  ellipsis: false,
}

export default Text
