import React from "react";
import styled from "styled-components";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "large" | "small";
  color?: "red" | "default";
  onClick?: any;
}

const Button: React.FC<ButtonProps> = ({
  children,
  size,
  color,
  style,
  ...buttonProps
}) => {
  return (
    <ButtonWrapper size={size} color={color} style={style}>
      <HiddenButton {...buttonProps} />
      <ButtonContent>{children}</ButtonContent>
    </ButtonWrapper>
  );
};

export default Button;

const ButtonWrapper = styled.div<{
  size: "large" | "small";
  color: "red" | "default";
}>`
  z-index: 2;
  position: relative;
  background-image: url(/images/${(props) =>
    props.size === "large"
      ? "large-button.png"
      : props.color === "red"
      ? "red-button.png"
      : "button-2.png"});
  background-repeat: no-repeat;
  background-size: contain;
  height: ${(props) => (props.size === "large" ? "90px" : "101px")};
  width: ${(props) => (props.size === "large" ? "100%" : "240px")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.4em;
  color: #ffffff;
  text-shadow: 0px 0px 4px #9acaf4;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;

const HiddenButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const ButtonContent = styled.div``;
