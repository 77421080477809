import styled from "styled-components";
import { useCallback, useEffect, useState, useRef } from "react";
import { Text, Box, BoxProps, Flex, Divider } from "../../components";
import Image from "next/image";
import { space, typography } from "styled-system";
import { useRouter } from "next/router";
import useBreakpoint from "../../hooks/useBreakpoint";
import Link from "next/link";
import { useAuth } from "../../contexts/auth-context";
import { Popover } from "../../components/Popover";
interface StyledHeader {
  isScrollDown?: boolean;
  isScrollUp?: boolean;
}
const StyledHeader = styled("header")<StyledHeader>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  a {
    color: white;
  }
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;

  transition: all 0.2s ease-in-out;
  z-index: 5;
  ${(props) =>
    props.isScrollDown === true
      ? `
    top: -11em;
  `
      : `
    top: 0;
    
  `}
  ${(props) =>
    props.isScrollUp === true &&
    `
    background: linear-gradient(180deg, #021E4A 29.93%, rgba(2, 30, 74, 0) 87.75%);
    li a{
      color: black;
    }
  `}

  li a {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    :hover {
      font-weight: bold;
      cursor: pointer;
    }
  }
  .top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00baf0;
    background: linear-gradient(to left, #f46b45, #eea849);
    height: 50px;
    padding: 1em;
  }

  .menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    align-items: center;
    margin: 0;
    padding: 0;
    flex: 3;
    @media (max-width: 700px) {
      flex: unset;
    }
  }

  .menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #menu-toggle {
    display: none;
  }

  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }

  .menu-button::before {
    content: "";
    margin-top: -8px;
  }

  .menu-button::after {
    content: "";
    margin-top: 8px;
  }

  #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
    background-color: white;
  }

  #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }

  #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
    background-color: white;
  }

  @media (max-width: 700px) {
    .menu-button-container {
      display: flex;
    }
    .menu {
      opacity: 0;
      position: absolute;
      top: 0;
      margin-top: 100px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    #menu-toggle ~ .menu li {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu li {
      height: 3.7em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu {
      opacity: 1;
    }

    .menu > li {
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      position: relative;
    }

    li:nth-child(2):before {
      position: absolute;
      content: " ";
      background-color: white;
      height: 1px;
      width: 100%;
    }
    li:last-child {
      display: none;
    }
    a {
      color: black;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    a:hover {
      font-weight: bold;
    }
    .menu > li:not(:last-child) {
      border-bottom: 1px solid #444;
    }
  }
`;

interface Nav {
  justifyContent: string;
}
const Nav = styled("nav")<Nav>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  left: 50%;
  justify-content: ${(props) => props.justifyContent};
`;

const StyledLogo = styled("div")`
  img {
    object-fit: contain !important;
    width: 100% !important;
    position: relative !important;
  }
`;

interface MobileMenu {
  isOpenNav?: boolean;
}
const MobileMenu = styled("nav")<MobileMenu>`
  transition: all 0.2s linear;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #021e4a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  opacity: 0;
  ${(props) =>
    props.isOpenNav &&
    `
    visibility: unset;
    opacity: 1;
  `}
`;

interface StyledMenuText {
  isOpenNav?: boolean;
  isScrollUp?: boolean;
  type?: string;
}

const StyledMenuText = styled(Text)<StyledMenuText>`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 0px 4px #9acaf4;
  :hover {
    color: #fedb45;
  }

  position: relative;
  padding: 1em 0em;
  border: 0;
  margin: 0.6em;
  background: transparent;
  color: white;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 1em 2.5em;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border: 0.5px solid white;
      background: ${(props) =>
        props.type === "outline" ? "transparent" : "#ED1C24"};
      transform: skew(150deg);
      :hover {
        background: red;
      }
    }
  }

  ${(props) =>
    props.isOpenNav === true &&
    `
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1em;
  `}
`;

const MenuItem = styled("li")<BoxProps>`
  margin: 0;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  :not(:first-child) {
    margin-left: -15px;
  }
  ${space}
  ${typography}
`;

const StyledMobileRegisterButton = styled("div")`
  ${({ theme }) => theme.mediaQueries.xs} {
    display: flex;
    padding: 1em 2.5em;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    text-shadow: 0px 0px 4px #9acaf4;
    justify-content: center;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border: 0.5px solid white;
      background: #ed1c24;
      transform: skew(150deg);
      :hover {
        background: red;
      }
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }
`;

interface StyledInput {
  ref: any;
}
const StyledInput = styled("input")<StyledInput>``;
const ScrollPosition = {
  UP: "Up",
  DOWN: "Down",
};

const StyledFilterBackground = styled("div")`
  background: linear-gradient(
    180deg,
    #021e4a 29.93%,
    rgba(2, 30, 74, 0) 87.75%
  );
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  height: 130px;
`;

const HeaderComponent = () => {
  const screens = useBreakpoint();
  const [isOpenNav, setIsOpenNav] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const router = useRouter();
  const menuToggleRef = useRef<HTMLHeadingElement>();

  const isScrollDown = scrollPosition === ScrollPosition.DOWN && scrollY > 50;
  const isScrollUp = scrollPosition === ScrollPosition.UP && scrollY > 50;

  useEffect(() => {
    if (screens.xs === false && isOpenNav === true) {
      setIsOpenNav(false);
    }
  }, [screens.xs]);

  useEffect(() => {
    if (isOpenNav === true) {
      document.body.style.overflow = "hidden";
      // document.body.style.width = 'calc(100% - 17px)'
      document.body.style.touchAction = "none";
      document.body.style.height = "100%";
      // document.body.style.position = 'fixed'
      document.body.style["-ms-touch-action"] = "none";
    } else {
      document.body.style.overflow = null;
      document.body.style.width = null;
      document.body.style.touchAction = null;
      document.body.style.height = null;
      document.body.style.position = null;
      document.body.style["-ms-touch-action"] = null;
    }
  }, [isOpenNav]);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (scrollY > window.scrollY) {
        setScrollPosition(ScrollPosition.UP);
      } else if (scrollY < window.scrollY) {
        setScrollPosition(ScrollPosition.DOWN);
      }
      setScrollY(window.scrollY);
    },
    [scrollY]
  );

  useEffect(() => {
    setScrollY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  const { isLoggedIn, logout, user } = useAuth();

  const menus = [
    {
      name: "Home",
      type: "outline",
      href: "/",
    },
    {
      name: `What's Pro Hub`,
      type: "outline",
      href: "/what-is-prohub",
    },
    {
      name: `News`,
      type: "outline",
      href: "/news",
    },
    {
      name: `Events`,
      type: "outline",
      href: "/events",
    },
    {
      name: `Our Facility`,
      type: "outline",
      href: "/facilities",
    },
    // {
    //   name: `Tournament`,
    //   type: "outline",
    //   href: "/tournament",
    // },
    {
      name: isLoggedIn ? "Logout" : "Register/Login",
      type: "default",
      href: null,
      onClick: () => (isLoggedIn ? logout() : router.push("/register")),
      style: {
        fontFamily: "Orbitron",
      },
    },
  ];

  const profileContent = (
    <div>
      <Flex flexDirection="column">
        <Flex alignItems="center">
          <Link href="/profile">
            <Text color="black">Profile</Text>
          </Link>
        </Flex>
        <Divider style={{ margin: "12px 0" }} />
        <Flex alignItems="center">
          <Link href="/profile/bookings">
            <Text color="black">Booking Info</Text>
          </Link>
        </Flex>
      </Flex>
    </div>
  );
  return (
    <>
      <StyledHeader isScrollDown={isScrollDown} isScrollUp={isScrollUp}>
        <StyledFilterBackground />
        <MobileMenu isOpenNav={isOpenNav} />
        <Box maxWidth="1400px" minHeight={screens.xs ? "80px" : "100px"}>
          <Nav justifyContent={screens.xs && "space-between"}>
            {/* <StyledLogo onClick={() => router.push("/")}>
              <Image src="/images/logo.png" alt="logo" fill />
            </StyledLogo> */}
            <img
              src="/images/logo.png"
              alt="logo"
              width="236px"
              style={{ maxWidth: "100%" }}
            />
            <StyledInput
              ref={menuToggleRef}
              id="menu-toggle"
              type="checkbox"
              onClick={() => setIsOpenNav(!isOpenNav)}
            />
            <label className="menu-button-container" htmlFor="menu-toggle">
              <div className="menu-button" />
            </label>
            <ul className="menu">
              <StyledMobileRegisterButton
                onClick={() => {
                  if (isLoggedIn) {
                    logout();
                  } else {
                    router.push("/register");
                  }
                }}
              >
                {isLoggedIn ? "Logout" : "Register/Login"}
              </StyledMobileRegisterButton>
              {menus.map((item, index) => (
                <MenuItem key={index}>
                  {item.href ? (
                    <Link href={item.href} as={item.href} shallow={true}>
                      <StyledMenuText
                        type={item.type || "default"}
                        isOpenNav={isOpenNav}
                        isScrollUp={isScrollUp}
                        style={item?.style}
                      >
                        {item.name}
                      </StyledMenuText>
                    </Link>
                  ) : (
                    <StyledMenuText
                      onClick={item.onClick}
                      type={item.type || "default"}
                      isOpenNav={isOpenNav}
                      isScrollUp={isScrollUp}
                      style={item?.style}
                    >
                      {item.name}
                    </StyledMenuText>
                  )}
                </MenuItem>
              ))}
              {isLoggedIn && (
                <Popover content={profileContent} trigger="click">
                  <MenuItem style={{ marginLeft: "27px" }}>
                    <Flex style={{ gap: "10px" }} alignItems="center">
                      <img width="45" src="/images/user-icon.svg" alt="User" />
                      <Text>{user?.first_name}</Text>
                    </Flex>
                  </MenuItem>
                </Popover>
              )}
            </ul>
          </Nav>
        </Box>
      </StyledHeader>
    </>
  );
};

export default HeaderComponent;
