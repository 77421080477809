import React, { useState } from "react";
import styled from "styled-components";
import { Field, useField, FieldHookConfig } from "formik";

const Select: React.FC<any> = ({ label, options, defaultValue, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <SelectWrapper>
      <Label>{label}</Label>
      <StyledSelect
        {...field}
        {...props}
        defaultValue={defaultValue}
        isFocused={isFocused}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  font-family: "Noto Sans Thai", sans-serif;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0.1em;
`;

const StyledSelect = styled(Field).attrs({ as: "select" })`
  display: block;
  width: 100%;
  background-color: ${({ isFocused }) =>
    isFocused ? "#ffffff" : "transparent"};
  border: 1px solid #9acaf4;
  font-size: 1rem;
  color: ${({ isFocused }) => (isFocused ? "#000000" : "#ffffff")};
  padding: 0.5rem;

  &:focus {
    outline: none;
  }

  option {
    color: #000000;
    background-color: #ffffff;
  }
`;

export default Select;
