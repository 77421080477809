import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form, Field, FieldHookConfig, useField } from "formik";

const BeautifulInput = ({
  label,
  extraText,
  isShowBorder = true,
  isAddMarginBottom = true,
  borderBottom = "2px solid white",
  ...props
}: any) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  // const _props: FieldHookConfig<string> =
  const [field, meta, helpers] = useField(props);

  return (
    <InputWrapper isAddMarginBottom={isAddMarginBottom}>
      <Label focused={focused || !!field.value}>{label}</Label>
      <StyledInput
        disabled={props.disabled}
        borderBottom={borderBottom}
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {extraText && <ExtraText>{extraText}</ExtraText>}
    </InputWrapper>
  );
};

export default BeautifulInput;

const InputWrapper = styled.div<{ isAddMarginBottom: boolean }>`
  position: relative;
  ${(props) =>
    props.isAddMarginBottom &&
    `
    margin-bottom: 1rem;
  `}
  font-family: "Noto Sans Thai", sans-serif;
`;

const Label = styled.label<{ focused: boolean }>`
  position: absolute;
  left: 0;
  top: ${({ focused }) => (focused ? "-1.5rem" : "0")};
  font-size: ${({ focused }) => (focused ? "0.75rem" : "1rem")};
  color: ${({ focused }) => (focused ? "#FFFFFF" : "#FFFFFF")};
  transition: all 0.3s;
  pointer-events: none;
  letter-spacing: 0.1em;
`;

const StyledInput = styled(Field)`
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  ${(props) =>
    props.borderBottom &&
    `
    border-bottom: ${props.borderBottom};
`}
  font-size: 1rem;
  color: #ffffff;
  padding: 0.5rem 0;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
  &:focus {
    outline: none;
  }
`;

const ExtraText = styled.span`
  display: block;
  font-size: 0.75rem;
  color: #ccc;
  margin-top: 0.25rem;
`;
