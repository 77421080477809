import styled, { keyframes } from "styled-components";
import { layout, space, typography } from "styled-system";
import { TextProps } from "../Text";

const blink = keyframes`
  0%  {
    color: rgba(255,255,255,0.4);
  }
  50% {
    color: rgba(255,255,255,1);
  }
  100% {
    color: rgba(255,255,255,0.4);
  }
`;

const StyledHeaderTitle = styled("h1")<TextProps>`
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: white;
  text-shadow: 0px 0px 4px #9acaf4;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  font-size: ${({ fontSize }) => fontSize || "40px"};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${space}
  ${typography}
  ${layout}
`;

const Heading = ({ children, ...rest }) => (
  <StyledHeaderTitle {...rest}>{children}</StyledHeaderTitle>
);

export default Heading;
