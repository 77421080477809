import React from "react";
import styled from "styled-components";

interface DividerProps {
  text?: string;
  style?: any;
  opacity?: string;
}

const Divider: React.FC<DividerProps> = ({ text, opacity, ...props }) => {
  return (
    <DividerWrapper opacity={opacity} {...props}>
      <DividerLine />
      {text && <DividerText>{text}</DividerText>}
      <DividerLine />
    </DividerWrapper>
  );
};

export default Divider;

const DividerWrapper = styled.div<{ opacity?: string }>`
  display: flex;
  align-items: center;
  margin: 30px 0;
  ${(props) =>
    props.opacity &&
    `
    opacity: ${props.opacity};
  `}
`;

const DividerLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #fff;
`;

const DividerText = styled.span`
  padding: 0 13px;
  font-size: 0.9rem;
  letter-spacing: 0.4em;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
`;
