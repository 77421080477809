import Header from "./components/Header";
import Footer from "./components/Footer";
import styled from "styled-components";


const StyledWrapper = styled("div")<{ bg?: string }>`
  background: url('${(props) =>
    props.bg ? props.bg : "/images/bg.webp"}') #031f4a;
  background-size: cover;
  background-position: top;
  height: 100%;
  width: 100%
  margin: 0;
  position: relative;
`;

interface MainLayoutProps {
  bg?: string;
  children: any;
}
const MainLayout = ({ children, bg }: MainLayoutProps) => {
  return (
    <StyledWrapper bg={bg}>
      <Header />
      <main>{children}</main>
      <Footer />
    </StyledWrapper>
  );
};

export default MainLayout;
